import React from "react";
import PropTypes from "prop-types";
import Section from "../Section";
import Image from "../Image";

import "./Section3.scss";

const OfficesSection3 = ({ data }) => {
  return (
    <Section className="tri-o" anchor={data.anchor}>
      <div className="tri-o jsTriOGrid">
        <div className="div div1">
          <h2
            className="title jsTriOTitle"
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          />
          <div className="text-w-border jsTriOContentLeft_DISABLED">
            {data.description}
          </div>
        </div>
        <div className="div div2">
          <Image
            className="jsTriOImg1"
            data={data.image1}
          />
        </div>
        <div className="div div3">
          <div className="tri-o__right">
            <div className="jsTriOContentRight">
              <div className="tri-o__right__label">by the numbers</div>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.content,
                }}
              />
            </div>
          </div>
        </div>
        <div className="div div4">
          <Image
            className="jsTriOImg2"
            data={data.image2}
          />
        </div>
        <div className="div div5">
          <Image
            className="jsTriOImg3"
            data={data.image3}
          />
        </div>
      </div>
    </Section>
  );
};

OfficesSection3.propTypes = {
  data: PropTypes.object,
};

export default OfficesSection3;
