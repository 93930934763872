import React, {useEffect, useCallback } from "react"
import { graphql } from 'gatsby';
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useInView } from 'react-intersection-observer';
import {
  navAnimation,
  moveLines,
  imageReveal,
  barReveal,
  bLabelReveal,
  revealText} from "../animations/Animate"
import { useData } from '../utils/DataProvider'
import LayoutInner from "../components/LayoutInner"
import Hero from "../components/Hero"
import SEO from "../components/Seo"
import LocalNav from "../components/LocalNav";
import OfficesSection1 from "../components/Offices/Section1";
import OfficesSection2 from "../components/Offices/Section2";
import OfficesSection3 from "../components/Offices/Section3";
import OfficesSection4 from "../components/Offices/Section4";
import OfficesSection5 from "../components/Offices/Section5";

gsap.registerPlugin(ScrollTrigger);

const tl = gsap.timeline();

const OfficesPage = ({data, location}) => {
  const [state, setState] = useData()
  const officesData = data.officesQuery;
  const sections = Object.values(officesData.sectionsQuery);

  useEffect(() => {
    setState({ ...state, color: officesData.meta.color });
  }, []);


  const officesAnimation = useCallback(() => {
    navAnimation()
  })
  const officeAnimObj = {
    autoAlpha: 0,
    stagger: 0.7,
    duration: 1,
    ease: "power3.easeOut",
  }

  const section1Offices = useCallback(() => {
    // DISABLED per client request
    // revealText('.module-one-o .ui-block__text', 2)

    // ScrollTrigger.batch('.jsModule1OContent > div', {
    //   onEnter: batch => gsap.from(batch, {
    //     ...officeAnimObj,
    //     y: 50
    //   }),
    // });
    gsap.from('.jsOneOImg', {
      duration: 1,
      opacity: 0,
      delay: 1,
      scale: 1.4,
      ease: "power4.easeOut",
    })
  })

  const section2Offices = useCallback(() => {

    revealText('.jsTwoOTitle .ui-block__text')
    gsap.from('.jsTwoOContent', {
      y: 50,
      autoAlpha: 0,
      stagger: 0.7,
      duration: .7,
      ease: "power3.easeOut",
      scrollTrigger: {
        trigger: '.jsTwoOContent',
        start: 'top 80%',
        toggleActions: 'play reverse play reverse',
      }
    })
    gsap.from('.jsTwoOIntroEl', {
      y: 50,
      autoAlpha: 0,
      stagger: 0.3,
      duration: 1,
      ease: "power3.easeOut",
      scrollTrigger: {
        trigger: '.jsTwoOTitle',
        start: 'top 70%',
        toggleActions: 'play reverse play reverse',
      }
    })
    imageReveal('.jsOS2Img1')
    imageReveal('.jsOS2Img2')
    imageReveal('.jsOS2Img3')
  })

  const section3Offices = useCallback(() => {
    // DISABLED per client request
    // revealText('.jsTriOTitle .ui-block__text')
    imageReveal('.jsTriOImg1')
    imageReveal('.jsTriOImg2')
    imageReveal('.jsTriOImg3')
    // DISABLED per client request
    // gsap.from('.jsTriOContentRight', {
    //   y: 100,
    //   autoAlpha: 0,
    //   duration: 1,
    //   ease: "power3.easeOut",
    //   scrollTrigger: {
    //     trigger: '.jsTriOContentRight',
    //     start: 'top 60%',
    //     toggleActions: 'play reverse play reverse',
    //   }
    // })
    gsap.from('.jsTriOContentLeft', {
      y: 50,
      autoAlpha: 0,
      duration: .7,
      ease: "power3.easeOut",
      scrollTrigger: {
        trigger: '.jsTriOContentLeft',
        start: 'top 80%',
        toggleActions: 'play reverse play reverse',
      }
    })
  })

  const section4Offices = useCallback(() => {

    revealText('.jsFourOTitle .ui-block__text')
    barReveal('.bar--0')
    barReveal('.bar--1')
    barReveal('.bar--2')
    barReveal('.bar--3')
    bLabelReveal('.b-label--0')
    bLabelReveal('.b-label--1')
    bLabelReveal('.b-label--2')
    bLabelReveal('.b-label--3')
    bLabelReveal('.b-label--4')


    moveLines('350px', '.jsMoveLinesPlans', '.jsSectionOffice4a')
    // DISABLED per client request
    // revealText('.jsFour1Title .ui-block__text')
    // ScrollTrigger.batch('.jsPlansItem', {
    //   onEnter: batch => tl.from(batch, {
    //     y: 50,
    //     autoAlpha: 0,
    //     stagger: 0.3,
    //     duration: 1,
    //     ease: "power3.easeOut",
    //   })
    // });
    gsap.from('.jsSkyBgr', {
      scale: 1.4,
      duration: 2,
      delay: 1,
      ease: "power4.easeIn",
      scrollTrigger: {
        trigger: '.jsSkyBgr',
        start: 'top center',
        scrub: true,
        toggleActions: 'play reverse play reverse',
      }
    })
  })

  const section5Offices = useCallback(() => {
    // DISABLED per client request
    // revealText('.jsFiveOTitle .ui-block__text')
    imageReveal('.jsFiveOImg1')
    imageReveal('.jsFiveOImg2')
    imageReveal('.jsFiveOImg3')
    // DISABLED per client request
    // gsap.from('.jsFiveOEl', {
    //   y: 100,
    //   autoAlpha: 0,
    //   duration: 1,
    //   stagger: 0.3,
    //   ease: "power3.easeOut",
    //   scrollTrigger: {
    //     trigger: '.jsFiveOTitle',
    //     start: 'top 80%',
    //     toggleActions: 'play none play none',
    //   }
    // })
    // gsap.from('.jsFiveOContentRight', {
    //   y: 50,
    //   autoAlpha: 0,
    //   duration: .7,
    //   ease: "power3.easeOut",
    //   scrollTrigger: {
    //     trigger: '.jsFiveOContentRight',
    //     start: 'top center',
    //     toggleActions: 'play reverse play reverse',
    //   }
    // })
  })


  useEffect(() => {
    officesAnimation();
    section1Offices();
    section2Offices();
    section3Offices();
    section4Offices();
    section5Offices();
  }, [])


  return (
    <LayoutInner location={location}>
      <SEO title={officesData.meta.title} />
      <Hero data={officesData.heroQuery} />
      <LocalNav sections={sections} />
      <OfficesSection1 data={officesData.sectionsQuery.section1}/>
      <OfficesSection2 data={officesData.sectionsQuery.section2} />
      <OfficesSection3 data={officesData.sectionsQuery.section3} />
      <OfficesSection4 data={officesData.sectionsQuery.section4} />
      <OfficesSection5 data={officesData.sectionsQuery.section5} />
    </LayoutInner>
  )
}

export default OfficesPage


export const query = graphql`
  query Offices {
    officesQuery: officesPageJson {
      meta {
        title
        shareImage
        keywords
        color
      }
      heroQuery: hero {
        title
        stripes
      }
      sectionsQuery: sections {
        section1 {
          name
          anchor
          title
          content
          videoID
          link
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        section2 {
          name
          anchor
          title
          content
          description
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image3 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        section3 {
          name
          anchor
          title
          content
          description
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image3 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        section4 {
          name
          anchor
          title
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          download
          sectors {
            text
          }
          floors {
            number
            size
          }
          planTitle
          plan {
            text
            download
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            thumb {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 350, height: 235)
              }
            }
            displayPlan
          }
        }
        section5 {
          name
          anchor
          title
          subtitle
          description
          button
          buttonUrl
          contentTitle
          contentDesc
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          image3 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

