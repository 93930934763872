import React from "react";
import PropTypes from "prop-types";

const icons = {
  play: {
    shape: (
      <>
        <ellipse cx="23.5" cy="23" rx="23.5" ry="23" fill="#FF7450"/>
        <path d="M29.2035 20.2139C31.2356 21.3622 31.2356 24.2893 29.2035 25.4376L22.5459 29.1996C20.546 30.3297 18.07 28.8848 18.07 26.5878V19.0638C18.07 16.7667 20.546 15.3219 22.5459 16.4519L29.2035 20.2139Z" fill="white"/>
      </>
    ),
  },
};

const SVG = ({ icon, size }) => {
  return <svg viewBox={size}>{icons[icon].shape}</svg>;
};

SVG.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  size: PropTypes.string,
};


export default SVG;
export { SVG };
