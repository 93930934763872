import React from "react";
import PropTypes from "prop-types";
import Section from "../Section";
import Image from "../Image";

import "./Section5.scss";
import { Link } from "gatsby";

const OfficesSection5 = ({ data }) => {
  return (
    <Section className="five-o" anchor={data.anchor}>
      <div className="five-o jsFiveOGrid">
        <div className="div div1">
          <div className="five-o__right">
            <div
              className="title jsFiveOTitle"
              dangerouslySetInnerHTML={{
                __html: data.title,
              }}
            />
            <h3 className="five-o__subtitle ui-bold jsFiveOEl">
              {data.subtitle}
            </h3>
            <p className="jsFiveOEl">{data.description}</p>
            <div className="jsFiveOEl">
              <Link className="btn" to={data.buttonUrl}>
                {data.button}
              </Link>
            </div>
          </div>
        </div>
        <div className="div div2">
          <Image
            className="jsFiveOImg1"
            data={data.image1}
          />
        </div>
        <div className="div div3">
          <div className="five-o__content jsFiveOContentRight">
            <h2 className="title ui-bold">{data.contentTitle}</h2>
            <p>{data.contentDesc}</p>
          </div>
        </div>
        <div className="div div4">
          <Image
            className="jsFiveOImg2"
            data={data.image2}
          />
        </div>
        <div className="div div5">
          <Image
            className="jsFiveOImg3"
            data={data.image3}
          />
        </div>
      </div>
    </Section>
  );
};

OfficesSection5.propTypes = {
  data: PropTypes.object,
};

export default OfficesSection5;
