import React from "react";
import PropTypes from "prop-types";
import LinesImage from "../images/lines-thin.svg";
import "./Lines.scss";

const Lines = ({ name }) => {
  return (
    <div className={`lines__root jsMoveLines${name}`}>
      <img src={LinesImage} className="lines-img" alt="lines" />
    </div>
  );
};

Lines.propTypes = {
  name: PropTypes.string,
};

Lines.defaultProps = {
  name: undefined,
};

export default Lines;
