import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Section from "../Section";
import Lines from "../Lines";
import ImageModal from "./ImageModal";
import "./Section4.scss";

const imageQuery = graphql`
  query sectionsOffices1 {
    officesSections1: officesPageJson {
      sectionsQuery1: sections {
        section4 {
          image1 {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

const OfficesSection4 = ({ data }) => {
  const skyImageQuery = useStaticQuery(imageQuery);
  const secOffices = Object.values(skyImageQuery.officesSections1.sectionsQuery1);
  const image = getImage(data.image);
  const bgr = getImage(data.image1);
  const displayedPlans = data.plan.filter((item, index) => item.displayPlan == "true");
  //console.log("d ", displayedPlans);
  return (
    <Section anchor={data.anchor} className="full">
      <Section className="four-o" backgroundImage={bgr}>
        <div className="four-o-image">
          <GatsbyImage
            image={image}
            alt=""
            className="four-o-image__item"
            objectFit="contain"
            objectPosition="50% 50%"
          />
          {data.floors.map((item, index) => (
            <div key={index} className={`b-label b-label--${index}`}>
              {item.number}
              <br />
              {item.size}
            </div>
          ))}
          {data.sectors.map((item, index) => (
            <div key={index} className={`bar bar--${index}`}>
              <em></em>
              <span className="bar__name">{item.text}</span>
            </div>
          ))}
        </div>
        <div className="module-four-o">
          <h2
            className="title jsFourOTitle"
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
        </div>
      </Section>

      <Section className="four-o1 jsSectionOffice4a" color={"gray"}>
        <Lines name="Plans" />
        <div className="plans__root">
          <h2
            className="title plans__title jsFour1Title"
            dangerouslySetInnerHTML={{ __html: data.planTitle != "-" ? data.planTitle : "" }}
          ></h2>
          <div className="plans">
            {displayedPlans.map((item, index) => (
              //item.displayPlan == "true" ?
              <div className="plans__item jsPlansItem" key={index}>
                <div>
                  <div
                    className="plans__text ui-bold"
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                  <ImageModal data={item} />
                </div>
                <a href={item.download} className="btn" target="_blank" rel="noreferrer">
                      Download PDF
                </a>
              </div>
              //: ""
            ))}
          </div>
        </div>
      </Section>
    </Section>
  );
};

OfficesSection4.propTypes = {
  data: PropTypes.object,
};

export default OfficesSection4;
