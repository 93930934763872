import { useState } from 'react';
import useKeypress from './useKeypress';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  function toggle() {
    setIsShowing(!isShowing);
  }
  useKeypress('Escape', () => {  setIsShowing(false)    });
  return {
    isShowing,
    toggle,
  }
};

export default useModal;