import React from "react";
import PropTypes from "prop-types";
import useModal from "../../utils/useModal";
import Modal from "../Modal";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ImageModal = ({ data }) => {
  const image = getImage(data.image);
  const thumb = data.thumb != "../images/agility/TESTESTTEST.jpg" ? getImage(data.thumb) : false;
  const { isShowing, toggle } = useModal();
  return (
    <>
      <div className="img-preview" onClick={() => toggle()}>
        {!!thumb && <GatsbyImage alt="" image={thumb} />}
      </div>
      <Modal isShowing={isShowing} hide={toggle} title={null}>
        <div className="image__wrap">
          <GatsbyImage alt="" image={image} />
        </div>
      </Modal>
    </>
  );
};

ImageModal.propTypes = {
  data: PropTypes.object,
};

export default ImageModal;
