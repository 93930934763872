import React, { useState } from "react";
import PropTypes from "prop-types";
import ModalVideo from "react-modal-video";
// import Img from "gatsby-image";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Section from "../Section";
import SVG from "../SVG";

import "./Section1.scss";

const OfficesSection1 = ({ data }) => {
  const image = getImage(data.image);
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Section className="one-o jsOneO" anchor={data.anchor}>
        <div className="one-o-image ">
          <GatsbyImage alt="" image={image} className="one-o-image__item jsOneOImg" />
        </div>
        <div className="module-one-o">
          <h2
            className="title"
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          />
          <div className="jsModule1OContent">
            <div
              dangerouslySetInnerHTML={{
                __html: data.content,
              }}
            />
            <div className="one-o__link">
              <a onClick={() => setOpen(true)}>
                <SVG icon="play" size="0 0 47 46" />
                <span className="link">
                  <span className="link__text">{data.link}</span>
                </span>
              </a>
            </div>
          </div>
        </div>
      </Section>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        youtube={{mute:1,autoplay:1}}
        videoId={data.videoID}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

OfficesSection1.propTypes = {
  data: PropTypes.object,
};





export default OfficesSection1;
