import React from "react";
import ReactDOM from "react-dom";

import "./Modal.scss";

const Modal = ({ isShowing, hide, showClose = true, className, children }) => isShowing ? ReactDOM.createPortal(
  <>
    <div className={`modal-overlay${className ? " modal-overlay--"+className : ""  }`}/>
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className={`modal${className ? " modal--"+className : ""  }`}>
        {showClose && <button type="button" className="modal__close" data-dismiss="modal" aria-label="Close" onClick={hide}>
          <span></span>
          <span></span>
        </button>
        }
        {children}
      </div>
    </div>
  </>, document.body
) : null;

export default Modal;
