import React from "react";
import PropTypes from "prop-types";
import Section from "../Section";
import Image from "../Image";

import "./Section2.scss";

const OfficesSection2 = ({ data }) => {
  return (
    <Section className="two-o jsTwoO" anchor={data.anchor}>
      <div className="two-o">
        <div className="two-o__intro jsTwoOIntro">
          <h2
            className="title jsTwoOIntroEl_DISABLED jsTwoOTitle_DISABLED"
            dangerouslySetInnerHTML={{
              __html: data.title,
            }}
          />
          <p className="jsTwoOIntroEl_DISABLED">{data.description}</p>
        </div>
        <div
          className="two-o__content jsTwoOContent_DISABLED"
          dangerouslySetInnerHTML={{
            __html: data.content,
          }}
        />
        <div className="two-o__images jsTwoOImages">
          <ul>
            <li>
              <Image
                className="jsOS2Img1"
                data={data.image1}
              />
            </li>
            <li>
              <Image
                className="jsOS2Img2"
                data={data.image2}
              />
            </li>
            <li>
              <Image
                className="jsOS2Img3"
                data={data.image3}
              />
            </li>
          </ul>
        </div>
      </div>
    </Section>
  );
};

OfficesSection2.propTypes = {
  data: PropTypes.object,
};

export default OfficesSection2;
