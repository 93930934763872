import React, { useState } from "react";
import PropTypes from "prop-types";
import ScrollSpy from "../utils/ScrollSpy";
import Section from "./Section";
import { useData } from "../utils/DataProvider";
import "./LocalNav.scss";

const LocalNav = ({ sections }) => {
  const [state, setState] = useData();
  const [active, setActive] = useState(null);
  const menu = sections.map(el => {
    return {
      name: el.name,
      id: el.anchor,
    };
  });

  const handleChange = value => {
    if (typeof window !== "undefined") {
      window.location.href = `#${value}`;
    }
  };
  const sectionsIDs = menu.map(item => item.id);

  return (
    <Section className="local-nav" data-sticky={state.isFixed}>
      <div className="local-nav">
        <ScrollSpy
          scrollTargetIds={sectionsIDs}
          activeNavClass="link--active"
          offset={-132}
        >
          <ul className="local-nav__menu jsLocalNav">
            {menu.map((item, index) => (
              <li key={index}>
                <a
                  href={`#${item.id}`}
                  className={`link ${active === item.id ? "link--active" : ""}`}
                  onClick={() => setActive(item.id)}
                >
                  <span className="link__text">{item.name}</span>
                </a>
              </li>
            ))}
          </ul>
        </ScrollSpy>
        <div className="local-nav__mobile mobile-nav">
          <select
            className="ui-select"
            onChange={e => handleChange(e.target.value)}
          >
            {menu.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
          <span className="mobile-nav__arrow"></span>
        </div>
      </div>
    </Section>
  );
};

LocalNav.propTypes = {
  title: PropTypes.string,
  menu: PropTypes.array,
  sections: PropTypes.array,
};

export default LocalNav;
